import { createThirdwebClient, ThirdwebClient } from "thirdweb";

// Create client with fallback clientId if env var is missing
const client: ThirdwebClient = createThirdwebClient({
  clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID || 'fallback-client-id',
});

// Log client initialization
console.log('[ThirdwebClient] Initialized with:', {
  clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID ? '[PRESENT]' : '[MISSING]',
  environment: process.env.NODE_ENV
});

export { client };
