"use client";

import { ConnectButton } from "thirdweb/react";
import { client } from "./client";
import { generatePayload, isLoggedIn, login, logout } from "../actions/auth";
import { defineChain, ethereum } from "thirdweb/chains";
import { goldContractAddress, LPV1ContractAddress, LPV2ContractAddress } from "../../lib/tokenDetails";
import { createWallet, inAppWallet } from "thirdweb/wallets";
import { useEffect, useState } from "react";

const pulsechain = defineChain({
  id: 369,
  icon: {
    url: "https://pulsechain.com/img/wordmarkShort.png",
    width: 129,
    height: 120,
    format: "png"
  }
});

export function LoginButton() {
  const [mounted, setMounted] = useState(false);

  // First useEffect just for mount state
  useEffect(() => {
    console.log('[LoginButton Debug] Component mounting');
    setMounted(true);
    return () => {
      console.log('[LoginButton Debug] Component unmounting');
      setMounted(false);
    };
  }, []);

  // Second useEffect for window-dependent operations
  useEffect(() => {
    if (mounted && typeof window !== 'undefined') {
      console.log('[LoginButton] Environment:', {
        hostname: window.location.hostname,
        environment: process.env.NODE_ENV,
        authDomain: process.env.NEXT_PUBLIC_THIRDWEB_AUTH_DOMAIN,
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
        clientInitialized: !!client
      });
    }
  }, [mounted]);

  // Early return with debug log
  if (!mounted) {
    console.log('[LoginButton Debug] Not mounted yet, returning null');
    return null;
  }

  return (
    <div style={{ 
      position: 'relative',
      isolation: 'isolate'
    }}>
      <style jsx global>{`
        .thirdweb-modal {
          position: relative !important;
          z-index: 9999 !important;
        }
        .thirdweb-modal-overlay {
          z-index: 9998 !important;
        }
      `}</style>
      <ConnectButton
        connectButton={{
          style: {
            color: "white",
            background: "linear-gradient(#272730, #2d4052)",
            position: "relative",
            zIndex: 1
          },
          label: "Login",
        }}
        detailsButton={{
          style: {
            position: "relative",
            zIndex: 1
          }
        }}
        autoConnect={true}
        client={client}
        chain={pulsechain}
        chains={[pulsechain, ethereum]}
        recommendedWallets={[
          createWallet("io.metamask"),
          createWallet("io.rabby"),
          createWallet("io.internetmoney"),
        ]}
        wallets={[
          inAppWallet({
            auth: {
              options: [
                "google",
                "discord",
                "telegram",
                "x",
                "passkey",
                "phone",
                "facebook",
                "apple",
                "guest",
                "email",
              ],
            },
          }),
          createWallet("io.metamask"),
          createWallet("io.rabby"),
          createWallet("io.internetmoney"),
          createWallet("walletConnect"),
        ]}
        connectModal={{
          size: "wide",
          title: "eXtreme Vision Gaming",
          titleIcon: "/xvglogo.webp",
          showThirdwebBranding: false,
        }}
        detailsModal={{
          showTestnetFaucet: false,
          hideSwitchWallet: false,
          hideDisconnect: false
        }}
        supportedTokens={{
          [pulsechain.id]: [
            {
              address: goldContractAddress,
              name: "Gold",
              symbol: "GOLD",
              icon: "/Gold.webp",
            },
            {
              address: LPV1ContractAddress,
              name: "LPV1",
              symbol: "LPV1",
              icon: "/LPV1.webp",
            },
            {
              address: LPV2ContractAddress,
              name: "LPV2",
              symbol: "LPV2",
              icon: "/LPV2.webp",
            },
          ],
        }}
        supportedNFTs={{
          [pulsechain.id]: []
        }}
        auth={{
          isLoggedIn: async (address) => {
            try {
              console.log("[LoginButton] Checking if logged in:", { address });
              const result = await isLoggedIn();
              console.log("[LoginButton] Login check result:", result);
              return result;
            } catch (error) {
              console.error("[LoginButton] Login check error:", error);
              return false;
            }
          },
          doLogin: async (params) => {
            try {
              console.log("[LoginButton] Logging in:", params);
              await login(params);
              console.log("[LoginButton] Login successful");
            } catch (error) {
              console.error("[LoginButton] Login error:", error);
              throw error;
            }
          },
          getLoginPayload: async ({ address }) => {
            try {
              console.log("[LoginButton] Getting login payload for:", address);
              const payload = await generatePayload({ address });
              console.log("[LoginButton] Generated login payload:", payload);
              return payload;
            } catch (error) {
              console.error("[LoginButton] Payload generation error:", error);
              throw error;
            }
          },
          doLogout: async () => {
            try {
              console.log("[LoginButton] Logging out");
              await logout();
              console.log("[LoginButton] Logout successful");
            } catch (error) {
              console.error("[LoginButton] Logout error:", error);
              throw error;
            }
          },
        }}
      />
    </div>
  );
}
