"use client";

import { ThirdwebProvider } from "thirdweb/react";
import { LoginButton } from "./consts/LoginButton";
import { useActiveAccount } from "thirdweb/react";
import { client } from "./consts/client";
import { useEffect } from 'react';

const HexagonPattern = () => {
  const hexSize = 3;
  const hexHeight = hexSize * 2;
  const hexWidth = hexSize * Math.sqrt(3);
  const yOffset = hexHeight * 0.75;
  
  const generateHexagons = () => {
    const hexagons = [];
    const cols = Math.ceil(200 / hexWidth) + 1;
    const rows = Math.ceil(200 / yOffset) + 1;
    
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const x = col * hexWidth + (row % 2) * (hexWidth / 2);
        const y = row * yOffset;
        
        const points = [];
        for (let i = 0; i < 6; i++) {
          const angle = (i * 60 + 30) * Math.PI / 180;
          const px = x + hexSize * Math.cos(angle);
          const py = y + hexSize * Math.sin(angle);
          points.push(`${px},${py}`);
        }
        
        hexagons.push(
          <path
            key={`hex-${row}-${col}`}
            className="hexagon"
            d={`M${points.join('L')}Z`}
            stroke="rgba(255, 255, 255, 0.1)"
            strokeWidth="0.5"
            fill="none"
          />
        );
      }
    }
    return hexagons;
  };

  return (
    <div className="hexagon-grid">
      <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
        viewBox="0 0 200 200"
      >
        <pattern
          id="hexagon-pattern"
          x="0"
          y="0"
          width="200"
          height="200"
          patternUnits="userSpaceOnUse"
        >
          {generateHexagons()}
        </pattern>
        <rect width="100%" height="100%" fill="url(#hexagon-pattern)" />
      </svg>
    </div>
  );
};

function ContentWrapper({ children }: { children: React.ReactNode }) {
  const account = useActiveAccount();
  
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      fetch('/api/init-bot').catch(console.error);
    }
  }, []);

  // Only show login button on allowed domains or in development
  const shouldShowLoginButton = () => {
    if (process.env.NODE_ENV === 'development') {
      console.log('[LoginButton Debug] Development mode, showing button');
      return true;
    }
    if (typeof window !== 'undefined') {
      const hostname = window.location.hostname;
      const allowedDomains = ['app.extremevisiongaming.com', 'www.extremevisiongaming.com'];
      console.log('[LoginButton Debug] Production check:', {
        currentHostname: hostname,
        allowedDomains,
        isDomainAllowed: allowedDomains.some(domain => hostname === domain)
      });
      return allowedDomains.some(domain => hostname === domain);
    }
    console.log('[LoginButton Debug] Window not defined, hiding button');
    return false;
  };
  
  return (
    <>
      <div className="flex flex-col">
        <div className="relative min-h-screen">
          <HexagonPattern />
          <div className="relative z-10">
            {children}
          </div>
        </div>
        {account && (
          <div className="fixed top-4 right-4 z-20 text-silver">
          </div>
        )}
      </div>
      {/* Login button container in bottom left with corrected z-index */}
      {shouldShowLoginButton() && (
        <div className="fixed bottom-2 left-4 z-30">
          <LoginButton />
        </div>
      )}
    </>
  );
}

export function AppContent({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Log environment in production
    if (process.env.NODE_ENV === 'production') {
      console.log('[AppContent] Production initialization:', {
        hostname: typeof window !== 'undefined' ? window.location.hostname : 'SSR',
        clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID ? '[PRESENT]' : '[MISSING]',
        authDomain: process.env.NEXT_PUBLIC_THIRDWEB_AUTH_DOMAIN ? '[PRESENT]' : '[MISSING]'
      });
    }
  }, []);

  return (
    <ThirdwebProvider>
      <style jsx global>{`
        /* Ensure modals always appear above other content */
        .thirdweb-modal-overlay {
          z-index: 40 !important;
        }
        .thirdweb-modal {
          z-index: 50 !important;
        }
      `}</style>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </ThirdwebProvider>
  );
}
